function SecWelcomeContent({img}){
  return(
      <div className="welcome-content">
          <div className="promo-section">
              <div className="integration-link">
                  <span className="integration-icon">
                      <img src={img} width="24" height="24" alt="" />
                  </span>
                  <span className="integration-text">Una nueva forma de gestionar tus finanzas!</span>
              </div>
          </div>
          <h1>Compra y vende tus cripto con cero riesgo en tu moneda nacional de forma sencilla y segura
          </h1>
          <p>
            Tu tienes el control total de tus finanzas, tu decides que comprar y dónde guardar tus cripto
            Nosotros no custodiamos tus cripto, solo tu tienes acceso a tus cripto
            .
          </p>
          {/*<div className="dream-btn-group">
              <a href="#" className="btn dream-btn mr-3">Learn More</a>
              <a href="#" className="btn dream-btn">Contact Us</a>
          </div>*/}
      </div>
  )
}

export default SecWelcomeContent
