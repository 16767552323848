export const Countries = {
  Mexico: 1,
  Peru: 2,
  Chile: 3,
  Honduras: 4,
};

export const resolveRatesFee = (data) => ({
  countryId: data.attributes.country.data.id,
  countryName: data.attributes.country.data.attributes.name,
  rateFee: data.attributes.rateFee / 100,
})
