import {

    HomeDemo2Services1,
    HomeDemo2Services2,
    HomeDemo2Services3,

    HomeDemo2TokenIcon1,

    HomeDemo2TeamImg1,
    HomeDemo2TeamImg2,
    HomeDemo2TeamImg3,
    HomeDemo2TeamImg4,

    HomeDemo2Partners1,
    HomeDemo2Partners2,
    HomeDemo2Partners3,
    HomeDemo2Partners4,
    HomeDemo2Partners5,
    HomeDemo2Partners6,
    HomeDemo2Partners7,
    HomeDemo2Partners8,
    HomeDemo2Partners9,
    HomeDemo2Partners10,
    HomeDemo2Partners11,
    HomeDemo2Partners12,

    HomeDemo2BlogImg1,
    HomeDemo2BlogImg2,
    HomeDemo2BlogImg3,

} from '../../utils/allImgs'
export const service_single_content = [
    {
        img:HomeDemo2Services1,
        title:'Mesa de OTC'
    },
    {
        img:HomeDemo2Services2,
        title:'Compra y venta de cripto'
    },
    {
        img:HomeDemo2Services3,
        title:'Swap de cripto'
    },

]
export const SocialListIco = [
    {icoName:"fa fa-facebook-square"},
    {icoName:"fa fa-twitter-square"},
    {icoName:"fa fa-github-square"},
    {icoName:"fa fa-instagram"},
    {icoName:"fa fa-medium"}
]
export const DocElementTitle = [
    {
        title:'WhitePaper'
    },
    {
        title:'OnePager'
    },
    {
        title:'Rating Review'
    },
    {
        title:'Marketing Plan'
    },
]

export const TokenText = [
    {
        text:'Lorem ipsum dolor sit amet, conse ctetur elit',
        img:HomeDemo2TokenIcon1
    },
    {
        text:'Sed quis accumsan nisi Ut ut felis',
        img:HomeDemo2TokenIcon1
    },
    {
        text:'felis congue nisl hendrerit commodo',
        img:HomeDemo2TokenIcon1
    },
    {
        text:'arch nemo sequi rem saepe ad quasi ullam.',
        img:HomeDemo2TokenIcon1
    },
]

export const MissionData = [
    {
        icoName:"ti-shine",
        title:"Quality"
    },
    {
        icoName:"ti-ruler-pencil",
        title:"Creativity"
    },
    {
        icoName:"ti-heart",
        title:"Reliability"
    },
]

export const TeamMember = [
    {
        img:HomeDemo2TeamImg1,
        title:'Sunny khan',
        text:'Executive Officer'
    },
    {
        img:HomeDemo2TeamImg2,
        title:'Ajoy Das',
        text:'Business Development'
    },
    {
        img:HomeDemo2TeamImg3,
        title:'Afroza Mou',
        text:'UX/UI Designer'
    },
    {
        img:HomeDemo2TeamImg4,
        title:'Lim Sarah',
        text:'Head of Marketing'
    },
    {
        img:HomeDemo2TeamImg1,
        title:'Sunny khan',
        text:'Executive Officer'
    },
    {
        img:HomeDemo2TeamImg2,
        title:'Ajoy Das',
        text:'Business Development'
    },
    {
        img:HomeDemo2TeamImg3,
        title:'Afroza Mou',
        text:'UX/UI Designer'
    },
    {
        img:HomeDemo2TeamImg4,
        title:'Lim Sarah',
        text:'Head of Marketing'
    },
]

export const PartnersData = [
    {img:HomeDemo2Partners1},
    {img:HomeDemo2Partners2},
    {img:HomeDemo2Partners3},
    {img:HomeDemo2Partners4},
    {img:HomeDemo2Partners5},
    {img:HomeDemo2Partners6},
    {img:HomeDemo2Partners7},
    {img:HomeDemo2Partners8},
    {img:HomeDemo2Partners9},
    {img:HomeDemo2Partners10},
    {img:HomeDemo2Partners11},
    {img:HomeDemo2Partners12}
]

export const BlogPost = [
    {img:HomeDemo2BlogImg1},
    {img:HomeDemo2BlogImg2},
    {img:HomeDemo2BlogImg3}
]
