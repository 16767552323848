import Main from '../template/main';

const Home = () => {

  return (
    <>
      <Main />
    </>
  );
}

export default Home;

