const LiA = ({icoName}) => <li><a href="/"><i className={icoName}></i></a></li>

const SecSocialList = ({data}) => {

  return (
    <div className="col-sm-12 text-left">
        <ul className="list-unstyled list-inline social-list">
        	{data && data.map((item , key) => (
    			<LiA
    				key={key}
    				icoName={item.icoName}
    			/>
        	))}
        </ul>
    </div>
  );
}

export default SecSocialList;
