import { useState, useEffect } from 'react';
import axios from 'axios';
import {Countries, resolveRatesFee} from '../enums /countriesEnum';

const DASHBOARD_API_URL = process.env.REACT_APP_API

const useSpreads = () => {
  const [MXNSpread, setMxnSpread] = useState();
  const [HNLSpread, setHnlSpread] = useState();

  useEffect(() => {
    const getSpreads = async () => {
      try {
        const response = await axios.get(`${DASHBOARD_API_URL}/rates-fees?populate=*`);

        const json = response.data;

        const result = json.data.map(feesData => resolveRatesFee(feesData));
        const _mxn = result.find(s => s.countryId === Countries.Mexico).rateFee;
        const _hnl = result.find(s => s.countryId === Countries.Honduras).rateFee;

        setMxnSpread(_mxn);
        setHnlSpread(_hnl);
      } catch (error) {
        console.error(error);
      }
    }

    getSpreads();
  }, []);

  return { MXNSpread, HNLSpread };
};

export default useSpreads;
