const SecContent = () => {
	return(
        <div className="col-12 col-md-7">
          {/* Content Info */}
          <div className="contact_info_area d-sm-flex justify-content-between">
            <div className="contact_info text-center wow fadeInUp" data-wow-delay="0.2s">
              <h5>ENLACES</h5>
              <a href><p>Anunciantes</p></a>
              <a href><p>Desarrolladores</p></a>
              <a href><p>Recursos</p></a>
              <a href><p>Empresa</p></a>
              <a href><p>Conectar</p></a>
            </div>
            {/* Content Info */}
            <div className="contact_info text-center wow fadeInUp" data-wow-delay="0.3s">
              <h5>PRIVACIDAD &amp; TOS</h5>
              <a href><p>Aceurdos Comerciales</p></a>
              <a href><p>Politicas de Privacidad</p></a>
              <a href><p>Acuerdos de Desarrollo</p></a>
            </div>
            {/* Content Info */}
            <div className="contact_info text-center wow fadeInUp" data-wow-delay="0.4s">
              <h5>CONTACTO</h5>
              <p>Mailing Address: 124-128 City Road</p>
              <p>London, England, EC1V 2NX</p>
              <p>alphegatech@gmail.com</p>
            </div>
          </div>
        </div>
	)
}

export default SecContent
