import {useEffect} from 'react'

import {Logo} from '../../data/data-layout/data-Header.js';

import {Addshrink , addActiveClass , OpenMenu , moveSmooth} from "../../utils/"

import './header.css'

import Preloader from '../../components/Preloader'

const Header = () => {

  useEffect(() => {
      Addshrink()
  },[])

  useEffect(() => {
      OpenMenu()
  },[])

  useEffect(() => {
      moveSmooth()
  },[])

  return (
    <>
      <Preloader />
      <header className="header-area wow fadeInDown" data-wow-delay="0.2s">
        <div className="classy-nav-container breakpoint-off">
          <div className="container">
            <nav className="classy-navbar justify-content-between" id="dreamNav">
              <a className="nav-brand" href="/">
                <img src={Logo} alt="logo"/>
                <span className="text-xl tracking-wide font-bold ml-2">ZETLON</span>
              </a>
              <div className="classy-navbar-toggler">
                <span className="navbarToggler" onClick={addActiveClass}>
                    <span />
                    <span />
                    <span />
                </span>
              </div>
              <div className="classy-menu">
                <div className="classycloseIcon">
                  <div className="cross-wrap" onClick={addActiveClass}>
                      <span className="top" />
                      <span className="bottom" />
                  </div>
                </div>
                <div className="classynav">
                  <ul id="nav">
                    <li><a onClick={moveSmooth} href="/#home">Home</a></li>
                    <li><a onClick={moveSmooth} href="/#services">Servicios</a></li>
                    <li><a onClick={moveSmooth} href="/rates">Tasas de Canbio</a></li>
                    <li><a onClick={moveSmooth} href="/#product">Producto</a></li>
                    <li><a onClick={moveSmooth} href="/#newsletter">Subscripción</a></li>
                    <li><a onClick={moveSmooth} href="/#contact">Contacto</a></li>
                    <li><a onClick={moveSmooth} href="https://dashboard.zetlon.io">Dashboard</a></li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
