import {useEffect, useState} from 'react';
import './style/HomeDemo2.scss'

import {service_single_content, SocialListIco,} from '../../data/data-containers/data-HomeDemo2.js';

import {HomeDemo2VideoBg5} from '../../utils/allImgs'

import {handelTitle} from '../../utils'
import Header from '../../layouts/Header'
import Footer from '../../layouts/FooterPages'

import SecWelcomeArea from './SecWelcomeArea'
import SecDemoVideo from './SecDemoVideo'
import SecOurServices from './SecOurServices'
import SecSubscribe from './SecSubscribe'

const Main = () => {

  useEffect(() => {
    handelTitle('Zetlon Exchange')
  },[])

  useEffect(() => {
    if (document.title === 'Zetlon Exchange') {
      document.getElementsByTagName("body")[0].style.backgroundColor = '#0f0f12'
    }else{
      document.getElementsByTagName("body")[0].style.backgroundColor = '#0f0f12'
    }
  },[])


  return (
    <>
      <Header />
      <div className="HomeDemo2">
        <SecWelcomeArea />
        <div className="clearfix" />
        <SecOurServices data={service_single_content} />
        {/*<CryptoRates data={currencyRates}/>*/}
        <SecDemoVideo img={HomeDemo2VideoBg5} />
        <SecSubscribe data={SocialListIco} />
      </div>
      <Footer />
    </>
  );
};

export default Main
