import React from 'react';
import SectionHeading from '../../../components/SectionHeading';
import Rate from './Rates';
function CryptoRates({data}) {
  return (
      <section className="crypto section-padding-100-70 clearfix" id="rates">
        <div className="container">

          <SectionHeading
              title="Tasas del dia"
              text="Tenemos las mejores tasas de cambio"/>
          <div className="col-md-12">
            <div className="crypto__main">
              <div className="content-inner">

                  {data && data.map((item, key) => (
                      <Rate
                          key={key}
                          title={item.title}
                          img={item.img}
                          unit={item.unit}
                          price={item.price}
                          bottom={item.bottom}
                          sale={item.sale}
                          status={item.status}
                      />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>
  );
}

export default CryptoRates;
