import './SectionHeading.css'

const SectionHeading = () => {

  return (
    <div className="section-heading text-center" data-aos="fade-up">
        <h2 className="title-head-subscribe">Mantente conectado a las últimas noticias del mundo cripto!</h2>
        <p className="text-head-subscribe"></p>
    </div>
  );
}

export default SectionHeading;
