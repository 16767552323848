import emailjs from '@emailjs/browser';
import {useEffect, useRef, useState} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const Form = () => {
	const textAreaRef = useRef(null);
	const recaptcha = useRef()
	const [formData, setFormData] = useState({
		from_name: "",
		from_email: "",
		subject: "",
		message: ""
	});
	const [errors, setErrors] = useState({});
	const [submitting, setSubmitting] = useState(false);
	const [emailJsFail, setEmailJsFail] = useState(false);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	}

	const form = useRef();

	const validateForm = (data) => {
		const captchaValue = recaptcha.current.getValue();
		const errors = {};

		if (!data.from_name.trim()) {
			errors.from_name = 'EL campo nombre es requerido';
		}

		if (!data.from_email.trim()) {
			errors.from_email = 'Email es requerido';
		} else if (!/\S+@\S+\.\S+/.test(data.from_email)) {
			errors.from_email = 'Email es inválido';
		}

		if (!data.subject.trim()) {
      errors.subject = 'El campo asunto es requerido';
    }

		if (!data.message.trim()) {
      errors.message = 'El campo mensaje es requerido';
    }

		if (!captchaValue) {
			errors.captcha = 'El campo reCAPTCHA es requerido';
		}

		return errors;
	};

	const sendEmail = (e) => {
		 e.preventDefault();
		const newErrors = validateForm(formData);
		setErrors(newErrors);
		if (Object.keys(newErrors)?.length === 0) {
			// Form doest contain errors
			setEmailJsFail(false)
			emailjs
					.sendForm('alphega_email_srv', 'zetlon_tpl', form.current, {
						publicKey: 'AjYMM7ecMbtyC7dWs',
					})
					.then(
							() => {
								setEmailJsFail(false)
								setSubmitting(true);
								setFormData({
									from_name: "",
									from_email: "",
									subject: "",
									message: ""
								})
							},
							(error) => {
								setEmailJsFail(true)
								console.error('Email send FAILED...!', error);
							},
					);
		} else {
			console.error(`Form submission failed
             due to validation errors.`);
		}
	};


	useEffect(() => {
		textAreaRef.current.style.height = 'auto';
		textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
		textAreaRef.current.style.whiteSpace = 'pre-line';
		textAreaRef.current.style.wordWrap = 'break-word';

	}, [])

	return (
	    <div className="row justify-content-center">
	      <div className="col-12 col-md-10 col-lg-8">
	        <div className="contact_form">
						{Object.keys(errors).length === 0 && submitting ? (
								<div className="submit-success mb-3" data-aos="fade-up">
									<h3>Tu mensaje ha sido enviado exitosamente ✓</h3>
								</div>
						) : null}

						{emailJsFail && (<div className="email-fail mb-3" data-aos="fade-up"><h4>Hubo un error al enviar tu mensaje, <br/> inténtalo de nuevo más tarde</h4></div>)}
						<form ref={form} onSubmit={sendEmail} id="main_contact_form" noValidate>
	            <div className="row">
	              <div className="col-12 col-md-6" data-aos="fade-up">
	                <div className="group wow fadeInUp" data-wow-delay="0.2s">
	                  <input type="text"
													 name="from_name"
													 id="from_name"
													 maxLength="50"
													 required
													 value={formData.from_name}
													 onChange={handleChange}/>
	                  <span className="highlight" />
	                  <span className="bar" />
	                  <label>Name</label>
										{errors.from_name &&
												<span className="error">
                            {errors.from_name}
                        </span>
										}
	                </div>
	              </div>
	              <div className="col-12 col-md-6" data-aos="fade-up">
	                <div className="group wow fadeInUp" data-wow-delay="0.3s">
	                  <input type="text"
													 name="from_email"
													 id="from_email"
													 required
													 maxLength="50"
													 value={formData.from_email}
													 onChange={handleChange}/>
	                  <span className="highlight" />
	                  <span className="bar" />
	                  <label>Email</label>
										{errors.from_email &&
												<span className="error">
                            {errors.from_email}
                        </span>
										}
	                </div>
	              </div>
	              <div className="col-12" data-aos="fade-up">
	                <div className="group wow fadeInUp" data-wow-delay="0.4s">
	                  <input type="text"
													 name="subject"
													 id="subject"
													 required
													 maxLength="50"
													 value={formData.subject}
													 onChange={handleChange}/>
	                  <span className="highlight" />
	                  <span className="bar" />
	                  <label>Subject</label>
										{errors.subject &&
												<span className="error">
                            {errors.subject}
                        </span>
										}
	                </div>
	              </div>
	              <div className="col-12" data-aos="fade-up">
	                <div className="group wow fadeInUp" data-wow-delay="0.5s">
	                  <textarea name="message"
															id="message"
															maxLength="600"
															required
															value={formData.message}
															onChange={handleChange}
															ref={textAreaRef}
															rows="4"/>
	                  <span className="highlight" />
	                  <span className="bar" />
	                  <label>Message</label>
										{errors.message &&
												<span className="error">
                            {errors.message}
                        </span>
										}
	                </div>
	              </div>
								<div className="col-12 text-center fadeInUp"
										 data-wow-delay="0.6s">
									<div className="f text-center">
										<ReCAPTCHA className="d-inline-block mb-3" ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY}/>
										{errors.captcha &&
												<p className="error">
													{errors.captcha}
												</p>
										}	</div>
									<button type="submit" className="btn dream-btn mb-3" data-aos="fade-up">Enviar mensaje</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
	);
}

export default Form
