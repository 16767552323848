import SectionHeading from '../../../components/SectionHeading'
import SecVideoArea from './SecVideoArea'

const SecDemoVideo = ({img}) => {

  return (
    <section className="demo-video d-flex section-before section-padding-100" id="product">
        <div className="container">
            <SectionHeading
                title='Nuestra plataforma'
                text=''
            />
            <SecVideoArea img={img} />
        </div>
    </section>
  );
}

export default SecDemoVideo;
