import {useEffect, useState} from 'react';
import {handelTitle} from '../../utils';
import Header from '../../layouts/Header';
import CryptoRates from '../main/CryptoRates';
import Footer from '../../layouts/Footer';
import useSpreads from '../../utils/hooks/useSpreads';


const API_KEY = process.env.REACT_APP_API_KEY
const API_URL = process.env.REACT_APP_URL
const CURRENCY_API_URL = process.env.REACT_APP_CURRENCY_URL
const SPREAD = process.env.REACT_APP_SPREAD
const MX_SPREAD = process.env.REACT_APP_MX_SPREAD
const HNL_SPREAD = process.env.REACT_APP_HNL_SPREAD

const Rates = () => {
  const [currencyRates, setCurrencyRates] = useState([]);
  const { MXNSpread, HNLSpread } = useSpreads();

  useEffect(() => {
    handelTitle('Zetlon Exchange')
  },[])

  useEffect(() => {
    if (document.title === 'Zetlon Exchange') {
      document.getElementsByTagName("body")[0].style.backgroundColor = '#0f0f12'
    }else{
      document.getElementsByTagName("body")[0].style.backgroundColor = '#0f0f12'
    }
  },[])

  useEffect(() => {
    // format number to US dollar
    const USDollar = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    const currencies = ['BTC', 'TRX', 'BNB', 'USDT', 'ETH'];
    const laCurrencies = ['EUR', 'PEN', 'CLP',	'COP', 'UYU',	'HNL', 'GTQ',	'ARS','PYG', 'NGN',	'KES','GHS',  'DOP','CRC']
    // Make a fetch request for each currency in the array
    const fetchMXNData = async () => {
      const currency = 'MXN'
      const base = 'USDT'

      try {
        // Make a GET request using the Fetch API
        return await fetch(`${API_URL}?base=USDT&quote=${currency}&exchangeRate=2`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'client-api-key': `${API_KEY}`
          }
        }).then((response) => {
          return response.json();
        }).then((json) => {
          const mexicoSpread = MXNSpread ?? MX_SPREAD;
          const price = json.data ? ((json.data + (json.data * mexicoSpread))).toFixed(2) : '0.00';
          json.img = base.toLowerCase();
          json.title = currency.toUpperCase();
          json.unit = `USDT/${currency}`;
          json.price = USDollar.format(price);

          // Return the response data
          return json
        });
      } catch (error) {
        console.error(error);
        return { currency, error: true };
      }
    };

    const fetchCryptoData = async (currency) => {
      try {
        // Make a GET request using the Fetch API
        return await fetch(`${CURRENCY_API_URL}/${currency.toLowerCase()}.json`)
            .then((response) => {
              return response.json();
            }).then((json) => {
              const base = "usd";
              const value = json[currency.toLowerCase()][base];
              const price = value ? ((value + (value * SPREAD))).toFixed(2) : '0.00';
              json.img = currency.toLowerCase();
              json.title = currency.toUpperCase();
              json.unit = `${currency}/USD`;
              json.price = USDollar.format(price);

              // Return the response data
              return json
            });
      } catch (error) {
        console.error(error);
        return { currency, error: true };
      }
    }

    const fetchLaCurrenciesData = async () => {
      try {
        // Make a GET request using the Fetch API
        return await fetch(`${CURRENCY_API_URL}/usdt.json`)
            .then((response) => {
              return response.json();
            }).then((json) => {
              // Return the response data
              return laCurrencies.reduce((acc, currency) => {
                const hondurasSpread = HNLSpread ??  HNL_SPREAD;

                const base = "usdt";
                const value = json[base][currency.toLowerCase()];
                const price = value ? ((value + (value * (currency === 'HNL' ? hondurasSpread : SPREAD)))).toFixed(2) : '0.00';
                if (value) {
                  acc.push({
                    img: currency.toLowerCase(),
                    title: currency.toUpperCase(),
                    unit: `USDT/${currency}`,
                    price: USDollar.format(price),
                  });
                }
                return acc;
              }, [])
            });
      } catch (error) {
        console.error(error);
        return { error: error };
      }
    }

    const fetchAllCurrencies = async () => {
      const results = await Promise.all(currencies.map(fetchCryptoData));
      const mxnData = await fetchMXNData()
      const laCurrenciesData = await fetchLaCurrenciesData()
      setCurrencyRates([mxnData, ...results, ...laCurrenciesData]);
    };

    fetchAllCurrencies();

    // Set up an interval to call the API every 10 minutes
    const interval = setInterval(() => {
      fetchAllCurrencies();
    }, 600000);

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, [MXNSpread, HNLSpread]);

  return (
      <>
        <Header/>
        <div className="HomeDemo2">
          <div className="container h-100 mt-100">
                <CryptoRates data={currencyRates}/>
            </div>
        </div>
        <Footer/>
      </>
  );
};

export default Rates
