import React from 'react';
import Rates from '../template/rates';

const RatesPage = () => {
  return (
      <>
        <Rates/>
      </>
  );
};

export default RatesPage;
